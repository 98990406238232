<template>
    <div class="card bs-5 mt-4">
        <div class="fl-te-c pb-3 d-lg-none-sm-flex pt-3">
            <h4 class="font-inter-semi-bold">{{ mediaType === 'news' ? 'News' : 'Blog' }}</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.addModal.show()" text="Add"/>
            </div>
        </div>
        <custom-vue-table-card :title="mediaType === 'news' ? 'News' : 'Blog'" class="d-sm-none-lg-block"
                               :show-search-box="false" :fields="fields"
                               :url="mediaType === 'news' ? listNewsUrl : listBlogUrl"
                               :per-page="10" ref="table">

            <template #buttons>
                <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #publishing_date="{rowData}">
                <p v-if="rowData.publishing_date">{{ rowData.publishing_date }}</p>
                <p v-else>N/A</p>
            </template>

        </custom-vue-table-card>

        <modal :title="mediaType === 'news' ? 'Add News' : 'Add Blog'" ref="addModal" width="40r"
               header-color="primary" no-close-on-backdrop>
            <add-media :media-type="mediaType" @success="formSuccess"/>
        </modal>

        <delete-modal ref="deleteModal" :url="mediaType === 'news' ? deleteNewsUrl : deleteBlogUrl"
                      :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the item <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
        <media-listing-mobile-view @viewDetails="viewDetails" @setDelete="setDelete"
                                   class="d-lg-none" ref="mediaMobileView"></media-listing-mobile-view>
    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import MediaListingMobileView from './MediaListingMobileView';
import AddMedia from './AddMedia';

export default {
    name       : 'Media',
    components : { AddMedia, MediaListingMobileView },
    data () {
        return {
            mediaType     : this.$route.params.media_type,
            listNewsUrl   : urls.cms.media.news.list,
            listBlogUrl   : urls.cms.media.blog.list,
            deleteNewsUrl : urls.cms.media.news.delete,
            deleteBlogUrl : urls.cms.media.blog.delete,
            editingItem   : null,
            deletingItem  : null,
            fields        : [
                {
                    name       : 'title',
                    titleClass : 'w-50',
                    sortField  : 'title',
                    title      : 'Title'
                },
                {
                    name      : 'category.category_name',
                    sortField : 'category.category_name',
                    title     : 'Category'
                },
                {
                    name  : '__slot:publishing_date',
                    title : 'Publishing Date'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    watch : {
        '$route.params.media_type' : {
            handler () {
                this.mediaType = this.$route.params.media_type;
            }
        }
    },
    methods : {
        viewDetails (id) {
            this.$router.push('/cms/' + this.$route.params.media_type + '/media/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
            refs.mediaMobileView.loadData();

            this.editingItem = null;
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.table.refreshTable();
            this.$refs.mediaMobileView.loadData();
        }
    }
};
</script>

<style scoped>

</style>
