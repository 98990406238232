<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{media_type:mediaType}">
        <div class="row">
            <div class="col-12">
                <validated-text-area rows="10" label="Title*" name="Title" v-model="model.title"
                                     :rules="{required: true, max:150}" :disabled="loading"/>
            </div>

            <div class="col-12">
                <validated-input label="Small description*" name="Small description" v-model="model.brief_description"
                                 :rules="{required: true, max:15}" :disabled="loading"/>
            </div>

            <div class="col-12">
                <validated-text-area label="Full Description*" name="Full Description" v-model="model.full_description"
                                     :rules="{required: true, max:1000}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <validated-vue-select :options="options"
                                      label="Category*" name="Category"
                                      v-model="model.category" :rules="{required: true}"
                                      :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-date-picker label="Publishing Date*" name="Publishing Date" value-type="YYYY-MM-DD"
                                       v-model="model.publishing_date" format="DD-MM-YYYY"
                                       :rules="{required: true}" :disabled="loading" :disabled-date="disablePastDate"/>
            </div>
            <div class="col-12" v-if="mediaType === 'news'">
                <validated-input label="News Link*" name="News Link" v-model="model.news_link"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>

import cmsUrls from '../../../data/cms-urls';
import axios from 'secure-axios';

export default {
    name  : 'AddMedia',
    props : {
        mediaType : {
            type    : String,
            default : ''
        }
    },

    data () {
        return {
            addUrl      : '',
            optionsUrl  : '',
            options     : [],
            addNewsUrl  : cmsUrls.cms.media.news.addEdit,
            addBlogUrl  : cmsUrls.cms.media.blog.addEdit,
            typeOptions : [
                { label : 'Ventura News', value : 1 },
                { label : 'Other News', value : 2 }
            ],
            newsCategoryOptions : cmsUrls.masterData.MediaCategory.newsCategoryOptions,
            blogCategoryOptions : cmsUrls.masterData.MediaCategory.blogCategoryOptions
        };
    },
    beforeMount () {
        this.setUrl();
    },
    methods : {
        setUrl () {
            if (this.mediaType === 'news') {
                this.addUrl = this.addNewsUrl;
                this.optionsUrl = this.newsCategoryOptions;
            } else {
                this.addUrl = this.addBlogUrl;
                this.optionsUrl = this.blogCategoryOptions;
            }
            this.setOptions();
        },
        async setOptions () {
            const response = await axios.get(this.optionsUrl);
            const json = response.data;
            if (json.data) {
                this.options = json.data;
            }
        },
        formSuccess () {
            this.$notify('Successfully Added..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        disablePastDate (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        }
    }
};
</script>

<style scoped>

</style>
